@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: only light;
    }
}

/* Chrome */
@media (forced-colors: active) {
    :root {
        color-scheme: only light;
    }
}

:root {
    color-scheme: only light;
}

@font-face {
    font-family: InterVariable;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("/public/fonts/InterVariable.woff2") format("woff2");
}