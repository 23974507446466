:root {
    --bg-system: rgba(217, 217, 217, 1);
    --bg-area: rgba(255, 255, 255, 1);

    --text-link: rgba(66, 0, 201, 1);

    --text-area-primary: rgba(0, 0, 0, 1);
    --text-area-secondary: rgba(115, 118, 124, 1);
    --text-area-tertiary: rgba(83, 82, 85, 1);
    --text-area-delete: rgba(185, 31, 92, 1);

    --text-input-error: rgba(185, 31, 92, 1);
    --text-card-primary: rgba(73, 73, 73, 1);
    --text-card-secondary-90: rgba(255, 255, 255, 0.9);
    --text-card-tertiary: rgba(139, 139, 139, 1);

    --color-background-card-primary-default: rgba(226, 237, 255, 1);
    --bg-card-tertiary: rgba(247, 247, 249, 1);
    --border-card-secondary: rgba(242, 242, 242, 1);
    --border-input-primary: rgba(233, 233, 233, 1);

    --icon-primary: rgba(176, 179, 187, 1);
    --icon-fifth: rgba(0, 0, 0, 1);
    --icon-sixth: rgba(47, 0, 143, 1);
    --icon-eighth: rgba(170, 164, 182, 1);

    --bg-button-primary: rgba(91, 15, 245, 1);
    --bg-button-secondary: rgba(242, 236, 255, 1);
    --bg-button-tertiary: rgba(247, 247, 249, 1);
    --bg-button-additional: rgba(191, 215, 253, 1);

    --text-button-secondary: rgba(47, 0, 143, 1);
    --text-button-tertiary: rgba(66, 0, 201, 1);

    --bg-label-check: rgba(205, 242, 196, 1);

    --bg-notification-secondary: rgba(252, 245, 219, 1);
    --bg-notification-tertiary: rgba(251, 240, 224, 1);
    --bg-notification-error: rgba(255, 237, 250, 1);

    --bg-area-secondary-blur: rgba(62, 62, 62, 0.7);
    --bg-s-control-primary: rgba(243, 243, 243, 1);
    --bg-s-control-secondary: rgba(220, 207, 255, 1);
}